<template>
  <div class="customer-search">
    <v-text-field
        class="d-block my-2"
        type="number"
        v-model="searchQuery"
        @input="fetchCustomers"
        placeholder="بحث برقم تليفون العميل"
    ></v-text-field>
    <ul v-if="showResults" class="customer-results">
      <li v-for="customer in customers" :key="customer.id" @click="selectCustomer(customer)">
        {{ customer.name }} - {{customer.phone_number}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      searchQuery: '',
      customers: [],
      showResults: false,
      selectedCustomer: null,
      searchTimeout: null
    };
  },
  methods: {
    async fetchCustomers() {
      if (this.searchQuery.length < 3) {
        this.customers = [];
        this.showResults = false;
        return;
      }
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        try {
          const response = await this.store.dispatch('customer/searchCustomers', { search: this.searchQuery });
          this.customers = response.data;
          this.showResults = true;
        } catch (error) {
          console.error('Error fetching customers:', error);
        }
      }, 500);
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer;
      this.searchQuery = customer.name;
      this.showResults = false;
      this.$emit('customer-selected', this.selectedCustomer);
    }
  }
};
</script>

<style scoped>
.customer-search {
  position: relative;
}
.customer-results {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  width: calc(100% - 2px);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}
.customer-results li {
  padding: 8px 12px;
  cursor: pointer;
}
.customer-results li:hover {
  background-color: #f0f0f0;
}
</style>
